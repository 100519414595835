import i18n from '@/plugins/i18n'

// @todo: this should be fetched from backend side
export default () => {
  return [
    {
      value: 'dk',
      text: i18n.t('country_denmark')
    },
    {
      value: 'fi',
      text: i18n.t('country_finland')
    },
    {
      value: 'it',
      text: i18n.t('country_italy')
    },
    {
      value: 'lt',
      text: i18n.t('country_lithuania')
    },
    {
      value: 'us',
      text: i18n.t('country_us')
    },
    {
      value: 'se',
      text: i18n.t('country_sweden')
    }
  ]
}
