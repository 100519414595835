<template>
  <anonymous-template>
    <template v-slot:content>
      <component
        :is="currentView"
        :verificationData="verificationData"
        @submit="handleSubmit"
        @editIdentifier="editIdentifier"
        @skipPhone="toEmailStep"
      >
      </component>

      <confirm-dialog
        ref="customerSupportRequiredDialog"
        :title="customerSupportDialogTitle"
        :text="customerSupportDialogText"
        :showCancelButton="false"
      />
    </template>
  </anonymous-template>
</template>

<script>
import api from '@/helpers/api'
import auth from '@/auth'
import helpers from '@/helpers'
import userHelpers from '@/helpers/user'

import AnonymousTemplate from '@/components/Anonymous/Template.vue'
import EmailForm from '@/components/Signup/EmailForm'
import PhoneNumberForm from '@/components/Signup/PhoneNumberForm'
import VerificationForm from '@/components/Signup/VerificationForm'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  name: 'SignupView',
  components: { AnonymousTemplate, ConfirmDialog },
  data() {
    return {
      currentView: PhoneNumberForm,
      verificationData: {
        type: 'email',
        identifier: ''
      },
      doesPhoneExist: false,
      remainLoggingIn: false,
      customerSupportDialogTitle: this.$t('note'),
      customerSupportDialogText: '',
      phoneUid: null // user id of verified phone number, used to check if the provided email & phone are from the same user
    }
  },
  methods: {
    handleSubmit(data) {
      switch (this.currentView) {
        case EmailForm:
          this.showEmailVerification(data)
          break
        case VerificationForm:
          this.verifyIdentifier(data)
          break
        case PhoneNumberForm:
          this.showPhoneVerification(data)
          break
        default:
          break
      }
    },
    toEmailStep() {
      this.currentView = EmailForm
    },
    showEmailVerification(data) {
      this.verificationData = {
        type: 'email',
        identifier: data.email
      }
      this.currentView = VerificationForm
    },
    showPhoneVerification(data) {
      this.verificationData = {
        type: 'phone',
        identifier: data.phone
      }
      this.currentView = VerificationForm
    },
    showCustomerSupportRequiredDialog() {
      this.customerSupportDialogText = this.$t(
        'error_signup_multiple_acc',
        this.$store.state.signupData
      )
      this.$refs.customerSupportRequiredDialog.isOpening = true
    },
    /**
     * Verify indentifier.
     */
    async verifyIdentifier(data) {
      if (data.type === 'phone') {
        helpers.sendTrackingEvent(
          'ONBOARDING',
          'business_account_verify_phone_number'
        )
        await this.verifyPhone(data)
      } else {
        helpers.sendTrackingEvent('ONBOARDING', 'business_account_verify_email')
        await this.verifyEmail(data, this.doesPhoneExist)
      }
    },
    /**
     * Handle actions after verifying phone number
     */
    async verifyPhone(data) {
      try {
        const accessCheck = await auth.checkAccountExistence(data)

        if (accessCheck > 0) {
          this.doesPhoneExist = true
          this.phoneUid = helpers.getTokenInfo().uid
        }

        switch (accessCheck) {
          // show email form if the phone number does not exist in the system
          // or the phone owner does not belong to any company
          case 0:
          case 1:
            this.currentView = EmailForm
            break
          case 2:
            // show error message when phone owner belongs to a company but does not have BD access
            localStorage.clear()
            this.$store.dispatch('setSystemMessage', this.$t('error_no_access'))
            this.currentView = PhoneNumberForm
            break
          case 3:
            // log the user in when phone owner belongs to a company and has BD access
            this.remainLoggingIn = true
            auth.login(this, data)
            break
          default:
            break
        }
      } catch (error) {
        this.$store.dispatch('setSystemMessage', error)
      }
    },

    /**
     * Decide which action will be taken after verifying user email address
     */
    async verifyEmail(data, doesPhoneExist) {
      try {
        const accessCheck = await auth.checkAccountExistence(data)

        // after access check, if the user exists, get the uid from the token
        const userToken = helpers.getTokenInfo()
        const emailUid = userToken ? userToken.uid : null

        switch (accessCheck) {
          // if the email does not exist in the system
          case 0:
            if (doesPhoneExist) {
              await userHelpers.editUser(helpers.getTokenInfo().uid, {
                email: data.identifier
              })

              this.remainLoggingIn = true
              this.$router.push({
                path: '/setup',
                query: this.$route.query
              })
            } else {
              // if phone number does not exist, create new user with provided phone & email
              this.createUser(this.$store.state.signupData)
            }
            break
          // if the email exists in the system and does not belong to any company
          case 1:
            // check if email belong to the same user as phone number
            // if yes, redirect to setup screen
            if (emailUid === this.phoneUid) {
              this.$router.push({
                path: '/setup',
                query: this.$route.query
              })
            } else {
              // if no, that means user tries to use a phone number and an email belong to two different accounts
              // as this case is very rare, forward to customer support
              this.showCustomerSupportRequiredDialog()
            }
            break
          case 2:
            // show error message when email owner belongs to a company but does not have BD access
            localStorage.clear()
            this.$store.dispatch('setSystemMessage', this.$t('error_no_access'))
            this.currentView = EmailForm
            break
          case 3:
            // log the user in when email owner belongs to a company and has BD access
            // login endpoint is already called for user existance check, so just redirect user to homepage at this point
            this.$router.push({
              path: '/home',
              query: this.$route.query
            })
            break
          case 4:
            // redirect to select a company
            this.$router.push({
              path: '/companies',
              query: this.$route.query
            })
            break
          default:
            break
        }
      } catch (error) {
        let message = error.message

        if (error.message === 'email already exists') {
          message = this.$t('account_email_exist')
        } else if (error.message === 'phone already exists') {
          message = this.$t('account_phone_exist')
        }

        this.$store.dispatch('setSystemMessage', message)
      }
    },

    editIdentifier(type) {
      // check identifier to redirect user to the right form
      if (type === 'phone') {
        this.currentView = PhoneNumberForm
      } else {
        this.currentView = EmailForm
      }
    },
    /**
     * Create the account for user, then redirect user to company setup view
     */
    async createUser(signupData) {
      this.errorMessage = null
      // create user account, add profile_id to signup data
      const apiSettings = {
        method: 'post',
        service: 'users',
        url: 'v1/users',
        data: signupData
      }

      try {
        await api.promise(apiSettings)
        // save the profile id of the user, in order to proceed to company setup flow
        helpers.saveStorage('setting-up', 1)

        // track signup event success
        helpers.sendTrackingEvent(
          'ONBOARDING',
          'create_new_user_account_success'
        )

        this.remainLoggingIn = true
        // log the user in, and redirect user to company setup flow
        await auth.login(this, {
          type: 'email',
          identifier: signupData.email
        })
      } catch (error) {
        helpers.sendTrackingEvent(
          'ONBOARDING',
          'create_new_user_account_failed'
        )
        if (error[0].type !== 'unique_field_failed') {
          throw this.$t('g_internal_error')
        }
        const message =
          error[0].field === 'phone'
            ? this.$t('account_phone_exist')
            : this.$t('account_email_exist')
        this.$store.dispatch('setSystemMessage', message)
      }
    }
  },

  created() {
    if (this.remainLoggingIn) {
      return
    }

    // user who validated phone number but did not have business account can access home page after refreshing the page
    // clear token to prevent that
    window.onbeforeunload = () => {
      if (this.$route.name === 'Signup') {
        localStorage.clear()
      }
    }
  }
}
</script>
