<template>
  <v-row class="page page--anonymous">
    <v-col
      cols="12"
      sm="8"
      offset-sm="2"
      md="6"
      offset-md="3"
      class="anonymous__section anonymous__section--content text-left"
    >
      <div class="maintenance-overlay" v-if="isMaintenance"></div>
      <slot name="content"></slot>
    </v-col>
  </v-row>
</template>

<script>
import helpers from '@/helpers'
import introBgImg from '@/assets/intro-bg.jpg'

export default {
  name: 'AnonymousViewTemplate',
  data() {
    return {
      introBgImg: introBgImg
    }
  },
  computed: {
    isMaintenance() {
      return helpers.isUnderMaintenance()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.page--anonymous {
  margin-bottom: 200px;

  & > .col {
    position: relative;
  }
}

.maintenance-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
}
</style>
