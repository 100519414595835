<template>
  <div dense class="login">
    <h1 class="login__title pkmn-form-title">
      {{ $t('g_log_in') }}
    </h1>
    <p>
      {{ $t('login_text') }}
    </p>

    <v-form
      ref="form"
      :disabled="helpers.isUnderMaintenance()"
      @submit.prevent="verifyIdentifier"
    >
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            ref="input"
            v-model="formData.identifier"
            class="pkmn-input"
            :label="$t('login_identifier')"
            validate-on-blur
            :rules="[rules.identifier]"
            @blur="trimInput"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn
            class="signup--phone__button pkmn-button pkmn-button--circle"
            color="primary"
            @click="verifyIdentifier"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-row dense v-if="errorMessage">
      <v-col cols="12" class="text-right">
        <p class="pkmn-form__error" v-html="errorMessage"></p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  isValidNumber,
  parsePhoneNumber,
  getPhoneCode
} from 'libphonenumber-js'

import api from '@/helpers/api'
import helpers from '@/helpers'
import rules from '@/helpers/validation'

export default {
  name: 'LoginForm',
  data() {
    return {
      formData: {
        type: '',
        identifier: ''
      },
      rules: rules,
      errorMessage: null,
      helpers: helpers
    }
  },
  watch: {
    'formData.identifier'(after, before) {
      // fill the country calling code when user enters a phone number
      if (before === '' && !isNaN(after)) {
        // remove 0 at the start of the number
        const processed = after.startsWith('0') ? after.substr(1) : after
        // add the country code
        this.formData.identifier = this.phoneCountryCode + processed
      }
    }
  },
  computed: {
    phoneCountryCode() {
      const country = helpers.getCountryByLanguage(this.$i18n.locale)
      return `+${getPhoneCode(country.toUpperCase())}`
    }
  },
  methods: {
    trimInput() {
      this.formData.identifier = this.formData.identifier.trim()
    },
    async verifyIdentifier() {
      // emit submission to show verification form in parent component
      helpers.sendTrackingEvent('LOG IN', 'click_business_account_log_in')
      if (!this.$refs.form.validate()) {
        return
      }

      // identify the type
      const data = {
        language_code: this.$i18n.locale // set the language for verification email / sms
      }

      if (isValidNumber(this.formData.identifier)) {
        this.formData.type = 'phone'
        this.formData.identifier = parsePhoneNumber(
          this.formData.identifier
        ).number
        data.phone = this.formData.identifier
      } else {
        this.formData.type = 'email'
        data.email = this.formData.identifier
      }

      const apiSettings = {
        method: 'post',
        service: 'users',
        url: 'v1/passcodes',
        data: data
      }

      try {
        // call endpoint to verify the identifier
        await api.promise(apiSettings)
        // save email to vuex to prefill the input if user wants to edit it later
        this.$store.dispatch('setLoginIdentifier', this.formData.identifier)
        this.$emit('submit', this.formData)
      } catch (error) {
        this.$store.dispatch('setSystemMessage', error)
      }
    }
  },
  mounted() {
    helpers.sendTrackingEvent('LOG IN', 'show_business_login_page')
    this.formData.identifier = this.$store.state.loginIdentifier
    this.$refs.input.focus()
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.login__title {
  margin-bottom: 8px;
}

.pkmn-checkbox {
  margin-top: 0;
  padding-top: 0;
}

.login__btn-forget-pwd {
  color: $color-brand;
}
</style>
