<template>
  <v-form ref="form" class="pkmn-form pkmn-form--car" lazy-validation>
    <v-row dense>
      <v-col cols="12">
        <v-text-field
          ref="newCarInput"
          :disabled="disabled"
          v-model="newCar"
          class="pkmn-input pkmn-input--add"
          :label="$t('settings_car_num')"
          append-icon="mdi-plus-circle"
          :rules="[rules.required, rules.noDuplication]"
          @click:append="addCar"
          @keydown="onNewCarInputKeydown"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row dense v-if="errorMessage">
      <v-col cols="12" class="text-right">
        <p class="pkmn-form__error" v-html="errorMessage"></p>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '@/helpers/api'
import rules from '@/helpers/validation'

export default {
  name: 'VehicleForm',
  props: ['disabled', 'list'],
  data() {
    return {
      newCar: '',
      rules: {
        ...rules,
        noDuplication: (value) =>
          !this.isCarDuplicated(value) ||
          this.$t('error_existed', { object: this.$t('g_car') })
      },
      errorMessage: null
    }
  },
  methods: {
    /**
     * Prevent default enter event on new car input box
     * Trigger add car action when Enter is pressed
     */
    onNewCarInputKeydown(event) {
      if (event.keyCode === 13) {
        event.preventDefault()
        this.addCar()
      }
    },
    /**
     * Check car if it is in the list
     * ABC-123, abc-123, ABC123 & ABC 123 are considered duplicated
     * @returns {Boolean} true if car is in the list, false otherwise
     */
    isCarDuplicated(licensePlate) {
      if (!licensePlate) {
        return -1
      }

      return (
        this.list.findIndex((item) => {
          const specialCharRegex = /[- ]/g
          return (
            item.reg_no.replace(specialCharRegex, '').toLowerCase() ===
            licensePlate.replace(specialCharRegex, '').toLowerCase()
          )
        }) > -1
      )
    },
    async addCar() {
      if (!this.$refs.form.validate()) {
        return
      }

      const apiSettings = {
        method: 'post',
        service: 'users',
        url: `v1/groups/${this.$store.state.selectedGroup}/cars`,
        data: {
          // handle the case when user submit multiple cars
          // trim the space between each number
          reg_no: this.newCar.replace(/\s/g, '')
        }
      }

      try {
        await api.promise(apiSettings)
        this.$emit('submit', this.newCar)
        this.$refs.form.reset()
      } catch (error) {
        this.$store.dispatch('setSystemMessage', error)
      }
    }
  }
}
</script>

<style lang="scss">
.pkmn-form--car .pkmn-input--add .v-icon {
  margin-right: 18px;
  margin-bottom: 4px;
}
</style>
