<template>
  <div class="signup">
    <h1 class="signup__title pkmn-form-title">
      {{ $t('signup_enter_email') }}
    </h1>

    <v-form ref="form" @submit.prevent="submitForm">
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            ref="emailInput"
            v-model="formData.email"
            class="pkmn-input"
            :label="$t('g_email')"
            validate-on-blur
            :rules="[rules.required, rules.email]"
            @blur="trimInput"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <!-- <v-row dense>
        <v-col cols="12">
          <v-select
            class="pkmn-input"
            v-model="formData.country"
            :items="countryOptions"
            :label="$t('g_country')"
            :rules="[rules.required]"
          ></v-select>
        </v-col>
      </v-row> -->
    </v-form>

    <v-row dense>
      <v-col cols="12" class="text-right">
        <v-btn
          class="signup__button pkmn-button pkmn-button--full-width-mobile"
          color="primary"
          @click="submitForm"
        >
          {{ $t('form_continue') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row dense v-if="errorMessage">
      <v-col cols="12" class="text-right">
        <p class="pkmn-form__error" v-html="errorMessage"></p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'

import api from '@/helpers/api'
import helpers from '@/helpers'
import rules from '@/helpers/validation'

import getCountryOptions from './countries'

export default {
  name: 'SignupEmailForm',
  data() {
    return {
      formData: {
        email: this.$store.state.signupData
          ? this.$store.state.signupData.email
          : ''
      },
      rules: rules,
      errorMessage: null,
      attempts: 0,
      maxAttempt: 5,
      rateLimiterPeriod: 300000, // 5 mins
      countryOptions: getCountryOptions()
    }
  },
  methods: {
    trimInput() {
      if (this.formData.email) {
        this.formData.email = this.formData.email.trim()
      }
    },
    async submitForm() {
      if (!this.$refs.form.validate()) {
        return
      }
      helpers.sendTrackingEvent('ONBOARDING', 'business_account_enter_email')

      // @todo: call backend to get passcode for inputted email
      const apiSettings = {
        method: 'post',
        service: 'users',
        url: 'v1/passcodes',
        data: {
          email: this.formData.email,
          language_code: this.$i18n.locale // set the language for verification email / sms
        }
      }

      try {
        // call endpoint to verify the identifier
        await api.promise(apiSettings)
        // save email to vuex to prefill the input if user wants to edit it later
        const signupData = this.$store.state.signupData || {}
        signupData.email = this.formData.email
        this.$store.dispatch('setSignupData', signupData)
        this.$emit('submit', this.formData)
      } catch (error) {
        this.$store.dispatch('setSystemMessage', error)
      }
    },

    resetAttemptsCounter() {
      this.attempts = 0
      helpers.saveStorage('acfs', moment().format('YYYY-MM-DD HH:mm:ss'))
    }
  },
  mounted() {
    helpers.sendTrackingEvent('ONBOARDING', 'business_account_show_email_form')
    this.$refs.emailInput.focus()
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.signup__error {
  margin-top: 24px;
  @include text-body;
  color: $color-danger;
}
</style>

<style lang="scss">
@import '@/style/common';
.signup__agree-box {
  margin-top: 0 !important;
}

.signup__agree-box .v-input__slot {
  align-items: flex-start;
}

.signup__agree-box .v-label {
  display: block !important;
  @include text-body;
}
</style>
