<template>
  <div class="page page--vehicles">
    <v-row>
      <v-col cols="12" sm="7" md="4">
        <h1 class="page__title">
          {{ $t('settings_vehicles_title') }}
        </h1>
        <p class="page__subtitle" v-html="$t('settings_vehicles_text')"></p>
      </v-col>

      <v-col
        cols="12"
        sm="5"
        md="4"
        offset-md="4"
        align="right"
        align-self="end"
      >
        <group-selector
          class="pkmn-button--full-width-mobile"
          v-if="auth.checkPermission('view-all-groups')"
          @input="selectGroup"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="4">
        <div class="vehicle__list-toggle-wrap">
          <v-switch
            v-if="$store.state.isPageReady"
            class="vehicle__list-toggle"
            v-model="isListEnabled"
            :label="
              $t(isListEnabled ? 'whitelist_enabled_2' : 'whitelist_disabled')
            "
            @change="setListState"
          />
          <div v-else class="vehicle__list-toggle-loader">
            <label>{{
              $t(isListEnabled ? 'whitelist_enabled_2' : 'whitelist_disabled')
            }}</label>
            <v-progress-circular color="#37bd00" indeterminate size="18" />
          </div>
        </div>
        <vehicle-form
          ref="form"
          :disabled="!isListEnabled"
          :list="vehicleList"
          @submit="addVehicle"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <vehicle-list
          :disabled="!isListEnabled"
          :data="vehicleList"
          @delete="openDeleteConfirmDialog"
        />
      </v-col>
    </v-row>

    <confirm-dialog
      ref="confirmDeleteDialog"
      warning
      :title="confirmDeleteTitle"
      :text="confirmDeleteText"
      @confirm="deleteVehicle"
      @close="selectedRecord = null"
    />
  </div>
</template>

<script>
import api from '@/helpers/api'
import auth from '@/auth'
import companyHelpers from '@/helpers/company'
import helpers from '@/helpers'

import ConfirmDialog from '@/components/ConfirmDialog'
import GroupSelector from '@/components/Groups/GroupSelector'
import VehicleForm from '@/components/Vehicles/Form'
import VehicleList from '@/components/Vehicles/List'

export default {
  name: 'VehiclesView',
  components: { ConfirmDialog, GroupSelector, VehicleForm, VehicleList },
  data() {
    return {
      auth: auth,
      isListEnabled: true,
      selectedRecord: null,
      confirmDeleteTitle: '',
      confirmDeleteText: '',
      vehicleList: []
    }
  },
  methods: {
    selectGroup() {
      // reload data when a group is selected
      this.loadData()
      this.$refs.form.$refs.newCarInput.resetValidation()
    },
    openDeleteConfirmDialog(record) {
      this.selectedRecord = record
      // prepare confirm dialog content
      this.confirmDeleteTitle = this.$t('remove_dialog_title', {
        object: record.reg_no
      })
      this.confirmDeleteText = this.$t('remove_vehicle_text', {
        plate_number: record.reg_no,
        group_name: companyHelpers.getGroupNameById(
          this.$store.state.selectedGroup,
          this.$store.state.groupData
        )
      })

      this.$refs.confirmDeleteDialog.isOpening = true
    },

    async setListState(state) {
      const apiSettings = {
        method: 'put',
        service: 'users',
        url: `v1/groups/${this.$store.state.selectedGroup}/cars/settings`,
        data: {
          enable: state
        }
      }

      try {
        await api.promise(apiSettings)
        this.loadData(true)
      } catch (error) {
        this.$store.dispatch('setSystemMessage', error)
        // roll back the change on calling backend failed
        this.isListEnabled = !state
      }
    },

    addVehicle(item) {
      // call endpoint to add vehicle to group whitelist
      this.$store.dispatch('setSystemMessage', {
        type: 'success',
        message: this.$t('added_confirm_message', {
          object: `${this.$t('g_car')} "${item}"`
        })
      })
      this.loadData(true)
    },

    async deleteVehicle() {
      const plateNumber = this.selectedRecord.reg_no
      const apiSettings = {
        method: 'delete',
        service: 'users',
        url: `v1/groups/${this.$store.state.selectedGroup}/cars`,
        data: {
          reg_no: plateNumber
        }
      }

      try {
        await api.promise(apiSettings)
        this.$store.dispatch('setSystemMessage', {
          type: 'success',
          message: this.$t('removed_confirm_message', {
            object: `${this.$t('g_car')} "${plateNumber}"`
          })
        })
        this.vehicleList = this.vehicleList.filter(
          (car) => car.reg_no !== plateNumber
        )
        this.$store.dispatch('removeVehicle', plateNumber)
      } catch (error) {
        this.$store.dispatch('setSystemMessage', error)
      }
    },

    async loadData(isForceUpdate) {
      let companyVehicles = this.$store.state.vehicleData
      let selectedGroup = this.$store.state.selectedGroup
      // if vehicles list are not loaded before
      // fetch basic dashboard info first
      if (!companyVehicles || isForceUpdate) {
        let company = this.$store.state.companyData
        if (!company || !selectedGroup) {
          await helpers.fetchDashboardData()
          company = this.$store.state.companyData
          selectedGroup = this.$store.state.selectedGroup
        }

        // fetch the vehicle list if not available
        // this also save the vehicle list into vuex
        companyVehicles = await companyHelpers.fetchCompanyVehicles(company.id)
      }

      // set the data to the right variable
      const groupVehicleData = companyHelpers.getGroupVehicles(
        selectedGroup,
        companyVehicles
      )

      this.vehicleList = groupVehicleData ? groupVehicleData.cars : []
      this.isListEnabled = groupVehicleData
        ? groupVehicleData.whitelist_enabled
        : false
    }
  },
  async mounted() {
    await this.loadData()
  }
}
</script>

<style lang="scss" scoped>
p.page__subtitle {
  margin-bottom: 0;
}
</style>

<style lang="scss">
@import '@/style/common';

.vehicle__list-toggle-wrap {
  margin-bottom: 32px;
}

.vehicle__list-toggle {
  .v-input__slot {
    flex-direction: row-reverse;
  }

  .v-label {
    font-weight: 400;
    padding-right: 16px;
  }
}

.vehicle__list-toggle-loader {
  margin-top: 20px;
  padding-bottom: 28px;

  display: grid;
  grid-template-columns: 1fr 28px;

  label {
    font-size: 16px;
    font-weight: 400;
  }
}
</style>
