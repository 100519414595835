<template>
  <div class="signup signup--phone">
    <h1 class="signup__title pkmn-form-title">
      {{ $t('signup_title') }}
    </h1>
    <!-- <p v-html="$t('signup_phone_text', { email: email })"></p> -->
    <p v-html="$t('signup_phone_title')"></p>

    <v-form
      ref="phoneForm"
      :disabled="helpers.isUnderMaintenance()"
      @submit.prevent="verifyPhoneNumber"
    >
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="formData.phone"
            class="pkmn-input"
            ref="phoneInput"
            single-line
            validate-on-blur
            :rules="[rules.phone]"
            :label="$t('g_phone')"
            @blur="trimInput"
          >
            <template v-slot:prepend-inner>
              <img
                v-if="isCountryExist"
                class="phone-input__flag"
                :src="`http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`"
              />
              <img
                v-else
                class="phone-input__flag"
                :src="require('@/assets/unknown-country.svg')"
              />
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-checkbox
            class="pkmn-checkbox signup__agree-box"
            v-model="formData.is_policy_accepted"
            :rules="[rules.legalAccepted]"
            @change="onLegalCheckboxClick"
          >
            <template v-slot:label>
              {{ $t('signup_consent_1') }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    :href="localizedTermsUrl"
                    @click.stop
                    @click="onTermsClick"
                    v-on="on"
                  >
                    {{ $t('g_terms_of_use') }}
                  </a>
                </template>
                {{ $t('g_open_in_new_window') }}
              </v-tooltip>
              {{ $t('signup_consent_2') }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    href="https://parkman.io/privacy-policy"
                    @click.stop
                    @click="onPrivacyPolicyClick"
                    v-on="on"
                  >
                    {{ $t('g_privacy_policy') }}
                  </a>
                </template>
                {{ $t('g_open_in_new_window') }}
              </v-tooltip>
              {{ $t('signup_consent_3') }}
            </template>
          </v-checkbox>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" class="text-right">
          <v-btn
            class="signup--phone__button pkmn-button pkmn-button--circle"
            color="primary"
            @click="verifyPhoneNumber"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row dense v-if="isTestEnv">
        <v-col cols="12" class="text-right">
          <a
            class="signup--phone__button--skip"
            color="primary"
            @click="skipPhoneStep"
          >
            Skip phone step (test env only)
          </a>
        </v-col>
      </v-row>

      <v-row dense v-if="errorMessage">
        <v-col cols="12" class="text-right">
          <p class="pkmn-form__error" v-html="errorMessage"></p>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import * as phoneNumberLib from 'libphonenumber-js'
import api from '@/helpers/api'
import helpers from '@/helpers'
import rules from '@/helpers/validation'

export default {
  name: 'PhoneNumberForm',
  data() {
    return {
      countryCode: null,
      formData: {
        phone: '+1',
        is_policy_accepted: false
      },
      errorMessage: null,
      isFirstLoad: true,
      rules: rules,
      helpers: helpers
    }
  },
  computed: {
    isCountryExist() {
      return phoneNumberLib.isSupportedCountry(this.countryCode)
    },
    isTestEnv() {
      return process.env.VUE_APP_ENV === 'test'
    },
    localizedTermsUrl() {
      return this.$t('terms_url');
    }
  },
  watch: {
    'formData.phone'() {
      if (this.isFirstLoad) {
        this.isFirstLoad = false
        return
      }

      try {
        this.countryCode = phoneNumberLib.parsePhoneNumber(
          this.formData.phone
        ).country
      } catch (e) {
        // in case country cannot be found, leave it blank
        this.countryCode = null
      }
    }
  },

  methods: {
    trimInput() {
      this.formData.phone = this.formData.phone.trim()
    },
    /**
     * Validate and send SMS confirmation to the number
     * @todo:
     *  - compare the number against supported countries
     */
    async verifyPhoneNumber() {
      if (!this.$refs.phoneForm.validate()) {
        return
      }

      helpers.sendTrackingEvent(
        'ONBOARDING',
        'business_account_enter_phone_number'
      )

      // invoke verification method from backend. Backend send sms to inputted phone number
      const apiSettings = {
        method: 'post',
        service: 'users',
        url: 'v1/passcodes',
        data: {
          phone: this.formData.phone,
          language_code: this.$i18n.locale // set the language for verification email / sms
        }
      }

      try {
        // sms sent successfully
        await api.promise(apiSettings)

        const signupData = this.$store.state.signupData || {}
        // save phone number to signup data in store
        // before saving, strip all special character e.g. space, ., -...
        const phoneNumber = phoneNumberLib.parsePhoneNumber(this.formData.phone)
        signupData.phone = phoneNumber.number
        // also save detected country to signup data
        signupData.country_code = phoneNumber.country

        this.$store.dispatch('setSignupData', signupData)
        this.$emit('submit', this.formData)
      } catch (error) {
        this.$store.dispatch('setSystemMessage', error)
      }
    },

    onLegalCheckboxClick() {
      helpers.sendTrackingEvent('ONBOARDING', 'check_accept_terms')
    },
    onTermsClick() {
      helpers.sendTrackingEvent('ONBOARDING', 'click_terms_of_use')
    },
    onPrivacyPolicyClick() {
      helpers.sendTrackingEvent('ONBOARDING', 'click_privacy_policy')
    },
    skipPhoneStep() {
      this.$emit('skipPhone')
    }
  },
  mounted() {
    // @todo: signup data might be null at the first place
    const initPhone = this.$store.state.signupData
      ? this.$store.state.signupData.phone
      : null

    if (initPhone) {
      // fill the phone number form if user come back from validation step
      this.isFirstLoad = false
      this.formData.phone = initPhone
    } else {
      const country = helpers.getCountryByLanguage(this.$i18n.locale)
      // detect the country for the flag and country calling code on first load
      this.countryCode = country.toUpperCase()
      this.formData.phone = `+${phoneNumberLib.getPhoneCode(this.countryCode)}`
    }

    this.$refs.phoneInput.focus()
    helpers.sendTrackingEvent(
      'ONBOARDING',
      'business_account_show_phone_number_form'
    )
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.signup__title {
  margin-bottom: 8px;
}

.phone-input__flag {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.signup--phone__button {
  margin-top: 8px;
}

.signup--phone__button--skip {
  margin-top: 32px;
  display: inline-block;
  font-weight: 700;
}
</style>
