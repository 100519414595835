<template>
  <anonymous-template>
    <template v-slot:content>
      <component
        :is="currentView"
        :verificationData="verificationData"
        @submit="handleSubmit"
        @editIdentifier="editIdentifier"
      >
      </component>
    </template>
  </anonymous-template>
</template>

<script>
import auth from '@/auth'

import AnonymousTemplate from '@/components/Anonymous/Template.vue'
import LoginForm from '@/components/LoginForm.vue'
import VerificationForm from '@/components/Signup/VerificationForm'

export default {
  name: 'LoginView',
  components: { AnonymousTemplate, LoginForm },
  data() {
    return {
      currentView: LoginForm,
      verificationData: {
        type: 'email',
        identifier: ''
      }
    }
  },
  methods: {
    handleSubmit(data) {
      switch (this.currentView) {
        case LoginForm:
          this.showVerification(data)
          break
        case VerificationForm:
          this.verifyIdentifier(data)
          break
        default:
          break
      }
    },
    showVerification(data) {
      this.verificationData = data
      // show verification form
      this.currentView = VerificationForm
    },
    async verifyIdentifier(data) {
      try {
        await auth.login(this, data)
      } catch (error) {
        this.$store.dispatch('setSystemMessage', error)
      }
    },
    editIdentifier() {
      // show the identifier input form
      this.currentView = LoginForm
    }
  }
}
</script>

<style lang="scss" scoped></style>
